import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import { CalendarIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import Container from '../../components/Container/Container'
import { wrapper, column, image, category, details, detailsText, detailsIcon } from './FeaturePost.styles'
import Wysiwyg from '../../components/Wysiwyg'
import Button from '../../components/Button'

const FeaturePost = ({ author, showCategory, date, excerpt, excerptWordLength, post }) => {
  const parsedExcerpt = `${post.excerpt?.substring(0, excerptWordLength)}...`
  const imageObject = getImage(post.featuredImage?.node.gatsbyImage)

  return (
    <Container variant="wide" css={wrapper}>
      <div tw="flex space-x-8 justify-center items-center desklet:flex-col">
        <div css={column}>
          {post.featuredImage && imageObject ? (
            <GatsbyImage image={imageObject} alt={post.featuredImage.node.altText} css={image} imgStyle={{ borderRadius: `1.5rem` }} />
          ) : (
            <StaticImage
              src="../../assets/images/featured-image-default.jpg"
              alt="default image"
              css={image}
              imgStyle={{ borderRadius: `1.5rem` }}
            />
          )}
          <div css={details}>
            {date && (
              <span css={detailsText}>
                <CalendarIcon css={detailsIcon} />
                {post.date}
              </span>
            )}
            {showCategory && post.categories?.nodes[0] ? <span css={category}>{post.categories?.nodes[0]?.name}</span> : null}
          </div>
        </div>
        <div css={column} tw="desklet:pt-16 tablet:pt-5">
          <h2 tw="mt-0">{post.title}</h2>
          {excerpt && <Wysiwyg tw="!text-black">{parsedExcerpt}</Wysiwyg>}
          <Button variant="primary" href={post.uri} tw="mt-5">
            Read More
          </Button>
        </div>
      </div>
    </Container>
  )
}

FeaturePost.propTypes = {
  author: PropTypes.bool,
  showCategory: PropTypes.bool,
  date: PropTypes.bool,
  excerpt: PropTypes.bool,
  excerptWordLength: PropTypes.number,
  // post,
}

export default FeaturePost
