import React, { useState } from 'react'
import { graphql } from 'gatsby'
import tw, { css } from 'twin.macro'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import SeoPlugin from 'gatsby-plugin-wpgraphql-seo'
import Container from '../components/Container'
import FeaturePost from '../pageBuilder/FeaturePost/FeaturePost'
import Button from '../components/Button'
import BlogTile from '../components/BlogTile'
import Seo from '../components/Seo'

const BlogCategory = ({ data }) => {
  const featuredImage = getImage(data?.nodes[0]?.featuredImage?.node.gatsbyImage)
  const newArr = []
  const uri = data.nodes[0]?.uri
  const slug = data.nodes[0]?.slug
  const viewAll = uri.split(`/${slug}/`)[0]
  for (let i = 0; i < 4 && i < data.nodes.length; i += 1) {
    newArr.push(data?.nodes[i])
  }
  return (
    <Container variant="wide" tw="py-10 border-b border-purple/20 tablet:py-5">
      <h2 tw="mb-8 mt-0 text-[2rem]">{data?.nodes[0]?.categories.nodes[0]?.name}</h2>
      <div tw="flex desklet:flex-col">
        <div tw="w-1/2 mr-8 desklet:mr-0 desklet:w-full desklet:pb-5">
          {featuredImage ? (
            <GatsbyImage
              tw="rounded-3xl mb-5 w-full"
              css={css`
                aspect-ratio: 16/9;
                box-shadow: 4px 4px 20px 5px #81318f40;
              `}
              image={featuredImage}
              alt={data.nodes[0].featuredImage.node.altText}
              imgStyle={{ borderRadius: `1.5rem` }}
            />
          ) : (
            <StaticImage
              src="../assets/images/featured-image-default.jpg"
              alt="default image"
              tw="rounded-3xl mb-5"
              css={css`
                aspect-ratio: 16/9;
                box-shadow: 4px 4px 20px 5px #81318f40;
              `}
              imgStyle={{ borderRadius: `1.5rem` }}
            />
          )}
        </div>
        <div
          tw="items-start w-1/2 desklet:w-full"
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
            grid-gap: 30px;
          `}
        >
          {newArr.map(post => (
            <div tw="text-black" key={post?.title}>
              <p tw="py-0 text-sm text-gray">{post?.date}</p>
              <p tw="font-bold pb-1 mt-0 leading-[1.5rem]">{post?.title}</p>
              {/* {post && ( */}
              <Button href={post?.uri} size="small">
                Read More
              </Button>
              {/* )} */}
            </div>
          ))}
        </div>
      </div>
      <Button tw="justify-end w-full mt-5 tablet:justify-start" href={`/category${viewAll}`}>
        View All
      </Button>
    </Container>
  )
}

const FamilyResources = ({ data }) => {
  const newArr = []
  for (let i = 1; i < data.all.nodes.length; i += 1) {
    newArr.push({
      title: data.all.nodes[i].title,
      date: data.all.nodes[i].date,
      id: data.all.nodes[i].id,
      categories: data.all.nodes[i].categories,
      uri: data.all.nodes[i].uri,
      featuredImage: data.all.nodes[i].featuredImage,
    })
  }

  const postsPerClick = 3
  const [numPosts, setNumPosts] = useState(postsPerClick)

  const handleShowMorePosts = () => {
    setNumPosts(numPosts + postsPerClick)
  }
  return (
    <>
      <Seo title="Family Resources" />
      <Container variant="wide" tw="py-10 tablet:py-5">
        <h1 tw="mb-0">Family Resources</h1>
      </Container>
      <FeaturePost post={data.all.nodes[0]} excerpt date author showCategory excerptWordLength={120} />
      {/* <BlogFeed blogPosts={newArr} title="Most Recent" button description="View some of our most recent posts" />
       */}
      <Container variant="wide" tw="py-10 tablet:py-5">
        <div
          css={[
            css`
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
              @media screen and (max-width: 400px) {
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
              }
            `,
            tw`gap-7`,
          ]}
        >
          {newArr?.slice(0, numPosts)?.map((post, index) => (
            <BlogTile key={post.id} title={post.title} date={post.date} image={post.featuredImage?.node} link={post.uri} />
          ))}
        </div>
        {numPosts < newArr?.length && (
          <Button variant="primary" tw="mx-auto mt-5" onClick={handleShowMorePosts}>
            View More
          </Button>
        )}
      </Container>
      <BlogCategory data={data.cwelcc} />
      <BlogCategory data={data.play} />
      <BlogCategory data={data.corner} />
      <BlogCategory data={data.family} />
    </>
  )
}

export default FamilyResources

export const pageQuery = graphql`
  query {
    all: allWpPost(sort: { fields: date, order: DESC }) {
      nodes {
        id
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
        uri
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
          }
        }
      }
    }
    cwelcc: allWpPost(sort: { fields: date, order: DESC }, filter: { categories: { nodes: { elemMatch: { name: { eq: "CWELCC" } } } } }) {
      nodes {
        id
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
        uri
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
          }
        }
      }
    }
    play: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { name: { eq: "Types of Play" } } } } }
    ) {
      nodes {
        id
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
        uri
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
          }
        }
      }
    }
    corner: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { name: { eq: "Cook's Corner" } } } } }
    ) {
      nodes {
        id
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
        uri
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
          }
        }
      }
    }
    family: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { name: { eq: "Family Resources" } } } } }
    ) {
      nodes {
        id
        categories {
          nodes {
            name
            slug
          }
        }
        author {
          node {
            name
          }
        }
        date(formatString: "MMMM DD, YYYY")
        slug
        uri
        title
        content
        excerpt
        featuredImage {
          node {
            altText
            gatsbyImage(placeholder: BLURRED, formats: [AUTO, WEBP], width: 1200)
          }
        }
      }
    }
  }
`
