import tw, { css } from 'twin.macro'

export const wrapper = tw`py-10 tablet:py-5`

export const column = tw`relative w-1/2 desklet:w-full desklet:my-5 desklet:px-0`

export const details = tw`
    absolute w-full flex justify-around items-center mt-4 text-xs font-bold desklet:w-full
  `

export const detailsText = tw`flex justify-center items-center text-black`

export const detailsIcon = tw`w-5 text-purple mr-1`

export const category = tw`rounded-full bg-purple-400 bg-opacity-90 w-min py-2 px-3 text-center text-xs font-bold whitespace-nowrap text-white`

export const image = [
  tw`flex rounded-3xl min-w-[300px] phone:min-w-0`,
  css`
    aspect-ratio: 16/9;
  `,
]
